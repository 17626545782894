import Index from "views/Index.jsx";
import Profile from "views/examples/Profile.jsx";
import Maps from "views/examples/Maps.jsx";
import Register from "views/Auth/Register.jsx";
import Login from "views/Auth/Login.jsx";
import Tables from "views/examples/Tables.jsx";
import Icons from "views/examples/Icons.jsx";
import Customers from 'views/Customers/Customers.jsx';
import Delivery from "./views/Delivery/Delivery";
import ItemTypes from "./views/ItemTypes/ItemTypes";
import Items from "./views/Items/Items";
import Debts from "./views/Debts/Debts";
import Orders from "./views/Orders/Orders";
import ExpenseTypes from "./views/ExpenseTypes/ExpenseTypes";
import Expenses from "./views/Expenses/Expenses";
import Suppliers from "./views/Suppliers/Suppliers";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    roles: ['admin, staff']
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-single-02 text-blue",
    component: Customers,
    layout: "/admin",
    roles: ['admin', 'staff']
  },
  {
    path: "/delivery",
    name: "Delivery",
    icon: "ni ni-delivery-fast text-orange",
    component: Delivery,
    layout: "/admin",
    roles: ['admin', 'staff']
  },
  {
    path: "/items",
    name: "Items",
    icon: "ni ni-bag-17 text-yellow",
    component: Items,
    layout: "/admin",
    roles: ['admin', 'staff']
  },
  {
    path: "/itemtypes",
    name: "Item Types",
    icon: "ni ni-bullet-list-67 text-red",
    component: ItemTypes,
    layout: "/admin",
    type: 'setting',
    roles: ['admin', 'staff']
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-basket text-info",
    component: Orders,
    layout: "/admin",
    roles: ['admin', 'staff']
  },
  {
    path: "/expenses",
    name: "Expenses",
    icon: "ni ni-money-coins text-pink",
    component: Expenses,
    layout: "/admin",
    roles: ['admin', 'staff']
  },
  {
    path: "/expensetypes",
    name: "Expense Type",
    icon: "ni ni-tag text-red",
    component: ExpenseTypes,
    layout: "/admin",
    type: 'setting',
    roles: ['admin', 'staff']
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    icon: "ni ni-circle-08 text-primary",
    component: Suppliers,
    layout: "/admin",
    roles: ['admin', 'staff']
  },
  // {
  //   path: "/debts",
  //   name: "Debts",
  //   icon: "ni ni-planet text-blue",
  //   component: Debts,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    roles: []
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
];
export default routes;
