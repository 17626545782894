import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { arraySum, sort, timeout, apiurl } from '../../services/common';
import { TableFooter } from '@material-ui/core';
import { defaultModel } from '../DefaultModel';
import { getData } from '../../services/fetch';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import debounce from "debounce-promise";
import { getDateForInput } from '../../services/date';
import { postData, putData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import {
    Button,
    Form,
} from "reactstrap";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    infoRow: {

    },
    infoCol: {
        border: 'none',
    },
    input: {
        border: 'none',
        width: '100%',
        borderBottom: '1px solid #e0e0e0',
    }
}));

export function OrderTableDetail(props) {
    const classes = useStyles();
    const items = props.items ? props.items : [];
    const { advancePayment, discount, tax, deliveryCharge } = props;

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">No.</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Brand</TableCell>
                        <TableCell align="right">Unit Price</TableCell>
                        <TableCell align="right">Qty</TableCell>
                        <TableCell align="right">Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        items.length > 0 ?
                            sort(items, 'sortOrder').map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell align="center">{i + 1}.</TableCell>
                                    <TableCell align="left">
                                        {item.item.itemName}
                                    </TableCell>
                                    <TableCell align="left">{item.item.itemBrand}</TableCell>
                                    <TableCell align="right">{item.perItemCost}</TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>
                                    <TableCell align="right">{item.totalCost}</TableCell>
                                </TableRow>
                            )) : (<></>)
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">SubTotal</TableCell>
                        <TableCell align="right">{arraySum(items, 'totalCost')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">Delivery</TableCell>
                        <TableCell align="right">{deliveryCharge}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">Tax (included)</TableCell>
                        <TableCell align="right">{tax}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">Discount</TableCell>
                        <TableCell align="right">{discount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">{arraySum(items, 'totalCost') - discount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">Advance</TableCell>
                        <TableCell align="right">{advancePayment}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">Paid</TableCell>
                        <TableCell align="right">{arraySum(items, 'totalCost') - discount - advancePayment + deliveryCharge}</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
}

function updateModel(model, key, value) {
    model = Object.assign({}, model, { [key]: value })
    return model;
}

const getCustomers = async inputValue => {
    var response = await getData("/api/Customers/GetCustomerSelectList?search=" + inputValue);
    return response.data;
};

const getItems = async inputValue => {
    var response = await getData("/api/Items/GetItemSelectList?search=" + inputValue);
    return response.data;
};

const getItemByBarcode = async barcode => {
    var response = await getData("/api/Items/GetItemByBarcode?barcode=" + barcode);
    return response.data;
};

const debouncedLoadCustomers = debounce(getCustomers, timeout, { leading: true });
const debouncedLoadItems = debounce(getItems, timeout, { leading: true });
const debouncedGetItemByBarcode = debounce(getItemByBarcode, timeout, { leading: true });

const createOrder = (date, customerID, guestCustomerName, customerAddress, township, customerPhone, orderItems, advancePayment, discount, deliveryCharge, tax, status, deliverBy, stateModifiedDate, remark) => {
    return { date, customerID, guestCustomerName, customerAddress, township, customerPhone, orderItems, advancePayment, discount, deliveryCharge, tax, status, deliverBy, stateModifiedDate, remark }
}

function OrderTable(props) {
    const classes = useStyles();
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [model, setModel] = useState(props.model ? props.model : defaultModel.ordersFromCustomer);
    const [date, setDate] = useState(model.date ? getDateForInput(model.date) : getDateForInput(new Date()));
    const [customerID, setCustomerID] = useState(model.customerID);
    const [guestCustomerName, setGuestCustomerName] = useState(model.guestCustomerName);
    const [customerAddress, setCustomerAddress] = useState(model.customerAddress);
    const [township, setTownship] = useState(model.township);
    const [customerPhone, setCustomerPhone] = useState(model.customerPhone);
    const [orderItems, setOrderItems] = useState(model.orderItems);
    const [tax, setTax] = useState(model.tax ? model.tax : 0);
    const [discount, setDiscount] = useState(model.discount ? model.discount : 0);
    const [deliveryCharge, setDeliveryCharge] = useState(model.deliveryCharge ? model.deliveryCharge : 0);
    const [advancePayment, setAdvancePayment] = useState(model.advancePayment);
    const [status, setStatus] = useState(model.status ? model.status : 1);
    const [deliverBy, setDeliverBy] = useState(model.deliverBy);
    const [stateModifiedDate, setStateModifiedDate] = useState(model.stateModifiedDate ? getDateForInput(model.stateModifiedDate) : undefined);
    const [remark, setRemark] = useState(model.remark);
    const [sortOrder, setSortOrder] = useState(model.orderItems.length);
    const [itemInputValue, setItemInputValue] = useState('');
    const [orderStates, setOrderStates] = useState([{ value: 1, label: 'Pre-order' }]);
    const [deliverymen, setDeliverymen] = useState([]);
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [barcode, setBarcode] = useState('');
    const [scanBarcodeState, setScanBarcodeState] = useState(""); // adding, notfound

    useEffect(() => {
        const fetchOrderStates = async () => {
            var response = await getData('/api/OrdersFromCustomers/GetOrderStateSelectList?action=' + (props.action ? props.action : ''));
            setOrderStates(response.data);
        };
        const fetchDeliverymen = async () => {
            var response = await getData('/api/Deliverymen/GetDeliverymenSelectList');
            setDeliverymen(response.data);
        };

        fetchOrderStates();
        fetchDeliverymen();
        if (props.action === "create") {
            return (
                setOrderItems([])
            )
        }
    }, [])

    useEffect(() => {
        const fetchCustomer = async () => {
            var response = await getData('/api/Customers/' + customerID);
            if (response.ok) {
                var customer = response.data;
                if (customer) {
                    setCustomerAddress(customer.homeAddress);
                    setTownship(customer.township);
                    setCustomerPhone(customer.phone);
                }
            }
        }
        if (isInitialMount) {
            setIsInitialMount(false);
        } else {
            fetchCustomer();
        }
    }, [customerID])

    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
        } else {
            if (status == 8 && model.status != status) {
                setStateModifiedDate(getDateForInput(new Date()));
            }
        }
    }, [status])

    useEffect(() => {
        if (!deliverBy && deliverymen.length > 0 && !model.id) {
            setDeliverBy(deliverymen.find(x => x.label == 'Pick up').value)
        }
    }, [deliverymen])

    useEffect(() => {
        const addItem = async () => {
            var itemlist = orderItems;
            var item = await debouncedGetItemByBarcode(barcode);
            if (item) {
                var newOrderItem = {
                    itemID: item.id,
                    perItemCost: item.perItemCost,
                    quantity: 1,
                    totalCost: item.perItemCost,
                    item: item,
                    sortOrder: sortOrder
                }
                setSortOrder(sortOrder + 1);
                itemlist.push(newOrderItem);
                setOrderItems(itemlist);
                setScanBarcodeState("");
            } else {
                setScanBarcodeState("notfound");
            }
            setBarcode("");
        }

        if (barcode && scanBarcodeState === "adding") {
            addItem();
        }
    }, [scanBarcodeState])

    const save = async (e) => {
        e.preventDefault();
        if (orderItems.length > 0) {
            var overQtyResponse = await postData('/api/OrdersFromCustomers/GetOverQtyItems', orderItems);
            if (overQtyResponse.ok) {
                var overQtyItems = overQtyResponse.data;
                if (overQtyItems && overQtyItems.length > 0) {
                    var message = '';
                    for (var i = 0; i < overQtyItems.length; i++) {
                        var item = orderItems.find(x => x.itemID.toLowerCase() == overQtyItems[0].id.toLowerCase());
                        if (item) {
                            message += (message ? ' and ' : '') + item.item.itemName;
                        }
                    }
                    if (message) {
                        message += (overQtyItems.length > 1 ? ' are ' : ' is ') + 'over the stock limit.';
                        setMessageModal(true);
                        setMessage(message);
                    }
                } else {
                    orderItems.map((item, i) => {
                        item.sortOrder = (i + 1);
                    })
                    var order = createOrder(date, customerID, guestCustomerName, customerAddress, township, customerPhone, orderItems, advancePayment, discount, deliveryCharge, tax, status, deliverBy, stateModifiedDate != undefined ? stateModifiedDate : null, remark);

                    var response = { ok: false, data: 'Something went wrong' };
                    if (props.action === 'create') {
                        order = Object.assign({}, order, { createdBy: 'current user' });
                        response = await postData('/api/OrdersFromCustomers', order);
                    } else if (props.action === 'edit') {
                        order = Object.assign({}, order, { id: model.id, createdBy: model.createdBy });
                        response = await putData('/api/OrdersFromCustomers/' + order.id, order);
                    }
                    if (response.ok) {
                        props.toggle();
                        props.refreshTable();
                    } else {
                        setMessageModal(true);
                        setMessage(response.data);
                    }
                }
            }
        } else {
            setMessageModal(true);
            setMessage("There is no items in order.");
        }
    }

    return (
        <>
            <MessageModal
                toggle={() => setMessageModal(false)}
                modal={messageModal}
                title={'Error'}
                message={message}
            />
            <Form onSubmit={save}>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={7} className={classes.infoCol}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={'18%'} align="left" className={classes.infoCol}>Order Date</TableCell>
                                                <TableCell className={classes.infoCol}><input type='date' value={date} onChange={e => setDate(e.target.value)} className={classes.input} /></TableCell>
                                                <TableCell width={'18%'} className={classes.infoCol}></TableCell>
                                                <TableCell className={classes.infoCol}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" className={classes.infoCol}>Customer</TableCell>
                                                <TableCell className={classes.infoCol}>
                                                    <AsyncSelect
                                                        isClearable
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        name="searchCustomer"
                                                        defaultValue={model.customer ? { value: model.customer.id, label: model.customer.name } : {}}
                                                        loadOptions={inputValue => debouncedLoadCustomers(inputValue)}
                                                        onChange={e => {
                                                            setCustomerID(!!e ? e.value : '00000000-0000-0000-0000-000000000000');
                                                            if (!!customerID) {
                                                                setGuestCustomerName('');
                                                            }

                                                        }}
                                                    />
                                                </TableCell>
                                                {
                                                    customerID == '00000000-0000-0000-0000-000000000000' ? (
                                                        <>
                                                            <TableCell align="left" className={classes.infoCol}>Customer Name</TableCell>
                                                            <TableCell className={classes.infoCol}><input type='text' value={guestCustomerName} onChange={e => setGuestCustomerName(e.target.value)} className={classes.input} required /></TableCell>
                                                        </>
                                                    ) : (<TableCell className={classes.infoCol} colSpan={2}></TableCell>)
                                                }
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" className={classes.infoCol}>Delivery By</TableCell>
                                                <TableCell className={classes.infoCol}>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        value={deliverymen.find(x => x.value.toLowerCase() == deliverBy.toLowerCase())}
                                                        // value={deliverymen.filter(x => x.value == model.deliverBy)}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        name="deliverBy"
                                                        options={deliverymen}
                                                        onChange={(e) => {
                                                            setDeliverBy(e.value)
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" className={classes.infoCol}>Address</TableCell>
                                                <TableCell className={classes.infoCol}><input type='text' className={classes.input} value={customerAddress} onChange={e => setCustomerAddress(e.target.value)} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" className={classes.infoCol}>Status</TableCell>
                                                <TableCell className={classes.infoCol}>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        value={status ? orderStates.filter(x => x.value == status)[0] : orderStates[0]}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        name="status"
                                                        options={orderStates}
                                                        onChange={(e) => {
                                                            setStatus(e.value)
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" className={classes.infoCol}>Township</TableCell>
                                                <TableCell className={classes.infoCol}><input type='text' value={township} onChange={e => setTownship(e.target.value)} className={classes.input} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {
                                                    status == 8 ? (
                                                        <>
                                                            <TableCell align="left" className={classes.infoCol}>Sale Date</TableCell>
                                                            <TableCell className={classes.infoCol}><input type='date' value={stateModifiedDate} onChange={e => setStateModifiedDate(e.target.value)} className={classes.input} /></TableCell>
                                                        </>
                                                    ) : (<TableCell colSpan={2} className={classes.infoCol}></TableCell>)
                                                }
                                                <TableCell align="left" className={classes.infoCol}>Phone</TableCell>
                                                <TableCell className={classes.infoCol}><input type='text' value={customerPhone} onChange={e => setCustomerPhone(e.target.value)} className={classes.input} /></TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={6} className={classes.infoCol}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">No.</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="left">Brand</TableCell>
                                <TableCell align="right">Unit Price</TableCell>
                                <TableCell align="right">Qty</TableCell>
                                <TableCell align="right">Amount</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orderItems.length > 0 ?
                                    sort(orderItems, 'sortOrder').map((item, i) => (
                                        <TableRow key={i}>
                                            <TableCell align="center">{i + 1}.</TableCell>
                                            <TableCell align="left">
                                                {item.item.itemName}
                                            </TableCell>
                                            <TableCell align="left">{item.item.itemBrand}</TableCell>
                                            <TableCell align="right">
                                                <input
                                                    type='number'
                                                    style={{ textAlign: 'right' }}
                                                    value={item.perItemCost}
                                                    onChange={e => {
                                                        orderItems[i]["perItemCost"] = e.target.value;
                                                        orderItems[i]["totalCost"] = orderItems[i]["perItemCost"] * orderItems[i]["quantity"];
                                                        setOrderItems(JSON.parse(JSON.stringify(orderItems)));
                                                    }}
                                                    className={classes.input}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <input
                                                    type='number'
                                                    style={{ textAlign: 'right' }}
                                                    value={item.quantity}
                                                    min={1}
                                                    onChange={e => {
                                                        orderItems[i]["quantity"] = e.target.value;
                                                        orderItems[i]["totalCost"] = orderItems[i]["perItemCost"] * orderItems[i]["quantity"];
                                                        setOrderItems(JSON.parse(JSON.stringify(orderItems)));
                                                    }}
                                                    className={classes.input}
                                                />
                                            </TableCell>
                                            <TableCell align="right">{item.totalCost}</TableCell>
                                            <TableCell align="right">
                                                <DeleteIcon onClick={() => {
                                                    orderItems.splice(i, 1);
                                                    setOrderItems(JSON.parse(JSON.stringify(orderItems)));
                                                }} />
                                            </TableCell>
                                        </TableRow>
                                    )) : (<></>)
                            }
                            <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">
                                    <div>
                                        <input
                                            type='text'
                                            placeholder={'Scan item to add...'}
                                            value={barcode}
                                            autoFocus={true}
                                            onKeyPress={(e) => {
                                                if (e.charCode === 13) {
                                                    e.preventDefault();
                                                    setScanBarcodeState("adding");
                                                }
                                            }}
                                            onChange={e => {
                                                setBarcode(e.target.value);
                                            }}
                                            className={classes.input}
                                        />
                                        <span style={{ color: 'rgba(0, 0, 0, 0.54)', float: 'left' }}>
                                            {
                                                scanBarcodeState === "adding" ? "Adding..." : (scanBarcodeState === "notfound" ? "Not found" : "")
                                            }
                                        </span>
                                    </div>
                                </TableCell>
                                <TableCell align="left" colSpan={3}>
                                    <AsyncSelect
                                        isClearable
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="searchItem"
                                        placeholder='Select item to add...'
                                        loadOptions={() => debouncedLoadItems(itemInputValue)}
                                        onInputChange={inputValue => setItemInputValue(inputValue)}
                                        value={""}
                                        onChange={(e) => {
                                            if (!!e) {
                                                var itemlist = orderItems;
                                                var item = e.value;
                                                var newOrderItem = {
                                                    itemID: item.id,
                                                    perItemCost: item.perItemCost,
                                                    quantity: 1,
                                                    totalCost: item.perItemCost,
                                                    item: item,
                                                    sortOrder: sortOrder
                                                }
                                                setSortOrder(sortOrder + 1);
                                                itemlist.push(newOrderItem);
                                                setOrderItems(itemlist);
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell colSpan={2}></TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                                <TableCell align="right">SubTotal</TableCell>
                                <TableCell align="right">{arraySum(orderItems, 'totalCost')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                                <TableCell align="right">Delivery</TableCell>
                                <TableCell align="right"><input type='number' className={classes.input} style={{ textAlign: 'right', marginRight: "-1rem" }} onBlur={() => { if (!deliveryCharge) setDeliveryCharge(0) }} value={deliveryCharge} onChange={e => setDeliveryCharge(e.target.value)} /></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                                <TableCell align="right">Tax (included)</TableCell>
                                <TableCell align="right"><input type='number' className={classes.input} style={{ textAlign: 'right', marginRight: "-1rem" }} onBlur={() => { if (!tax) setTax(0) }} value={tax} onChange={e => setTax(e.target.value)} /></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                                <TableCell align="right">Discount</TableCell>
                                <TableCell align="right"><input type='number' className={classes.input} style={{ textAlign: 'right', marginRight: "-1rem" }} onBlur={() => { if (!discount) setDiscount(0) }} value={discount} onChange={e => setDiscount(e.target.value)} /></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                                <TableCell align="right">Total</TableCell>
                                <TableCell align="right">{arraySum(orderItems, 'totalCost') - discount}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                                <TableCell align="right">Advance</TableCell>
                                <TableCell align="right"><input type='number' className={classes.input} style={{ textAlign: 'right', marginRight: "-1rem" }} onBlur={() => { if (!advancePayment) setAdvancePayment(0) }} value={advancePayment} onChange={e => setAdvancePayment(e.target.value)} /></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}></TableCell>
                                <TableCell align="right">Paid</TableCell>
                                <TableCell align="right">{(arraySum(orderItems, 'totalCost') - (parseInt(discount ? discount : 0) + parseInt(advancePayment ? advancePayment : 0))) ? (arraySum(orderItems, 'totalCost') - (parseInt(discount ? discount : 0) + parseInt(advancePayment ? advancePayment : 0)) + parseInt(deliveryCharge ? deliveryCharge : 0)) : 0}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.infoCol} width={'15%'}>Remark</TableCell>
                                                <TableCell className={classes.infoCol}>
                                                    <textarea className={classes.input} value={remark} onChange={e => setRemark(e.target.value)}></textarea>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align={'right'} className={classes.infoCol}>
                                                    <div className="pl-lg-4 text-right">
                                                        <Button color="primary" type="submit">Save</Button>
                                                        <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Paper>
            </Form>
        </>
    );
}

export function DeleteIcon(props) {
    return (
        <span className="" title="Remove">
            <button onClick={props.onClick} className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit" type="button">
                <span className="MuiIconButton-label">
                    <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">delete</span>
                </span>
                <span className="MuiTouchRipple-root"></span>
            </button>
        </span>
    )
}

export default OrderTable;