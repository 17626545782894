export function debounce(func, wait, immediate = false) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export function sort(array, field) {
	return array.sort(function (a, b) { return (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0); })
}

export function arrayRemove(arr, value, field = 'id') {
	return arr.filter(function (ele) {
		return ele[field] != value;
	});
}

export function arrayRemoveByIndex(arr, i) {
	return arr.filter(function (value, index) {
		return i != index;
	});
}

export function arraySum(arr, field) {
	return arr.length > 0 ? arr.sum(field) : 0;
}

Array.prototype.sum = function (prop) {
	var total = 0
	for (var i = 0, _len = this.length; i < _len; i++) {
		total += this[i][prop]
	}
	return total
}

export const timeout = 500;
export const apiurl = 'http://192.168.99.67'