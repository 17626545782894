import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    CardTitle,
} from "reactstrap";

// core components
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2
} from "variables/charts.jsx";
import {
    Redirect,
} from "react-router-dom";
import Header from "components/Headers/Header.jsx";
import { getToken, isInRole } from "../services/storage";
import { defaultModel } from "../components/DefaultModel";
import { getData } from "../services/fetch";
import ColInput from "../components/Form/Form";
import exportExcel, { excelType } from "../services/excel";
import { getDateForInput } from "../services/date";

class Index extends React.Component {
    state = {
        activeNav: 1,
        saleValueChartData: "data1",
        redirectToLogin: false,
        chartExample1: chartExample1,
        chartExample2: chartExample2,
        data: defaultModel.dashboard,
        financeStartDate: getDateForInput(new Date()),
        financeEndDate: getDateForInput(new Date()),
    };
    toggleNavs = (e, index) => {
        e.preventDefault();
        this.setState({
            activeNav: index,
            saleValueChartData:
                this.state.saleValueChartData === "data1" ? "data2" : "data1"
        });
        let wow = () => {
            console.log(this.state);
        };
        wow.bind(this);
        setTimeout(() => wow(), 1000);
        // this.chartReference.update();
    };
    async componentWillMount() {
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
        var token = await getToken();
        if (!token) {
            this.setState({ redirectToLogin: true });
        }
    }


    async componentDidMount() {
        var response = await getData('/api/Dashboard');
        if (response.ok) {
            this.setState({ data: response.data });
        }
    }


    render() {
        const thisprops = this;
        if (this.state.redirectToLogin) {
            return <Redirect to={{
                pathname: "/auth/login",
                state: { from: this.props.location }
            }} />
        }
        return (
            <>
                <div className="header pb-8 pt-5 pt-md-8" style={{ background: 'linear-gradient(87deg, silver 0, silver 100%)' }}>
                    <Container fluid>
                        <div className="header-body">
                            {/* Card stats */}
                            {
                                isInRole('admin') ? (
                                    <>
                                        <Row>
                                            <Col lg="2" xl="2">
                                                <Card className="card-stats mb-4 mb-xl-0">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle
                                                                    tag="h5"
                                                                    className="text-uppercase text-muted mb-0"
                                                                >
                                                                    Daily<br />Sale
                                                                </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">
                                                                    {this.state.data.dailySale}
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                    <i className="fas fa-chart-bar" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="2" xl="2">
                                                <Card className="card-stats mb-4 mb-xl-0">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle
                                                                    tag="h5"
                                                                    className="text-uppercase text-muted mb-0"
                                                                >
                                                                    Monthly<br />Sale
                          </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">
                                                                    {this.state.data.monthlySale}
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                                                    <i className="fas fa-chart-pie" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last week</span>
                      </p> */}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="2" xl="2">
                                                <Card className="card-stats mb-4 mb-xl-0">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle
                                                                    tag="h5"
                                                                    className="text-uppercase text-muted mb-0"
                                                                >
                                                                    Daily<br />Expense
                          </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">{this.state.data.dailyExpense}</span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                                    <i className="fas fa-money-check-alt" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Since yesterday</span>
                      </p> */}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="2" xl="2">
                                                <Card className="card-stats mb-4 mb-xl-0">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle
                                                                    tag="h5"
                                                                    className="text-uppercase text-muted mb-0"
                                                                >
                                                                    Monthly<br />Expense
                          </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">
                                                                    {this.state.data.monthlyExpense}
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                    <i className="fas fa-percent" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="2" xl="2">
                                                <Card className="card-stats mb-4 mb-xl-0">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle
                                                                    tag="h5"
                                                                    className="text-uppercase text-muted mb-0"
                                                                >
                                                                    Daily<br />Discount
                                                                </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">{this.state.data.dailyDiscount}</span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                                    <i className="fas fa-money-check-alt" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="2" xl="2">
                                                <Card className="card-stats mb-4 mb-xl-0">
                                                    <CardBody>
                                                        <Row>
                                                            <div className="col">
                                                                <CardTitle
                                                                    tag="h5"
                                                                    className="text-uppercase text-muted mb-0"
                                                                >
                                                                    Monthly<br />Discount
                                                                </CardTitle>
                                                                <span className="h2 font-weight-bold mb-0">
                                                                    {this.state.data.monthlyDiscount}
                                                                </span>
                                                            </div>
                                                            <Col className="col-auto">
                                                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                    <i className="fas fa-percent" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <ColInput
                                                col={3}
                                                label={'Start Date'}
                                                defaultValue={this.state.financeStartDate}
                                                value={this.state.financeStartDate}
                                                id={'startdate'}
                                                type={'date'}
                                                onChange={e => this.setState({ financeStartDate: e.target.value })}
                                            />
                                            <ColInput
                                                col={3}
                                                label={'End Date'}
                                                defaultValue={this.state.financeEndDate}
                                                value={this.state.financeEndDate}
                                                id={'enddate'}
                                                type={'date'}
                                                onChange={e => this.setState({ financeEndDate: e.target.value })}
                                            />
                                            <Col lg={3}>
                                                <label class="form-control-label" for="">Download Finance Report</label><br />
                                                <a className="btn" style={{ background: 'white' }}
                                                    onClick={() => {
                                                        exportExcel(excelType.finance, { startdate: this.state.financeStartDate, enddate: this.state.financeEndDate })
                                                    }}>
                                                    <span class="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">save_alt</span>
                                                </a>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (<></>)
                            }
                        </div>
                    </Container>
                </div>

                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        {
                            isInRole('admin') ? (
                                <Col className="mb-5 mb-xl-0" xl="8">
                                    <Card className="bg-gradient-default shadow">
                                        <CardHeader className="bg-transparent">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h6 className="text-uppercase text-light ls-1 mb-1">
                                                        Overview
                      </h6>
                                                    <h2 className="text-white mb-0">Sales value</h2>
                                                </div>
                                                {/* <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Week</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div> */}
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            {/* Chart */}
                                            <div className="chart">
                                                <Line
                                                    data={this.state.data.salesValue}
                                                    options={chartExample1.options}
                                                    getDatasetAtEvent={e => console.log(e)}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ) : (<></>)
                        }
                        <Col xl="4">
                            <Card className="shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                Performance
                      </h6>
                                            <h2 className="mb-0">Total orders</h2>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {/* Chart */}
                                    <div className="chart">
                                        <Bar
                                            data={this.state.data.totalOrder}
                                            options={chartExample2.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Index;
