import { getDateForInput } from "../services/date";

export const defaultModel = {
    customer: {
        name: '',
        dateOfBirth: '',
        phone: '',
        homeAddress: '',
        workAddress: '',
        township: '',
        city: '',
        typeOfSkin: '',
        facebookName: '',
        facebookLink: '',
        email: '',
        website: '',
        usedAmount: '',
        remark: '',
        isDelete: false,
        createdBy: '',
        createdDate: '',
        modifiedBy: '',
        modifiedDate: '',
        debtDueDate: '',
        shopID: '',
        debt: [],
    },
    deliveryman: {
        name: '',
        nrc: '',
        phone: '',
        homeAddress: '',
        email: '',
        facebook: '',
        remark: '',
        isDelete: false,
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
        shopID: '',
    },
    itemType: {
        type: '',
        remark: '',
        shopID: '',
        isDelete: false,
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
    },
    item: {
        itemName: '',
        barcode: '',
        itemBrand: '',
        itemTypeID: '',
        isPreOrderItem: false,
        url: '',
        description: '',
        quantityInStock: '',
        purchasePrice: 0,
        weightCost: 0,
        exchangeRate: 1,
        tax: 0,
        perItemCost: 0,
        remark: '',
        supplierID: '',
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
        shopID: '',
        itemPictures: []
    },
    itemPictures: {
        name: '',
        orderNo: 0
    },
    ordersFromCustomer: {
        voucherNo: '',
        date: '',
        customerID: '00000000-0000-0000-0000-000000000000',
        advancePayment: 0,
        discount: 0,
        deliveryCharge: 0,
        tax: 0,
        status: 1,
        deliverBy: '',
        deliverStatus: '',
        isDelete: '',
        guestCustomerName: '',
        customerAddress: '',
        township: '',
        customerPhone: '',
        remark: '',
        shopID: '',
        stateModifiedDate: '',
        customer: { name: '' },
        deliveryman: null,
        orderItems: [],
        orderState: null,
    },
    orderItems: {
        orderID: '',
        itemID: '',
        perItemCost: 0,
        quantity: 1,
        totalCost: 0,
        shopID: null,
        sortOrder: 0,
        item: null,
        shop: null
    },
    expenseType: {
        type: '',
        remark: '',
        shopID: '',
        isDelete: false,
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
    },
    expense: {
        date: getDateForInput(new Date()),
        description: '',
        amount: 0,
        expenseTypeID: '',
        remark: '',
        shopID: '',
        isDelete: false,
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
    },
    supplier: {
        name: '',
        phone: '',
        email: '',
        facebook: '',
        address: '',
        supplierPercentage: 5,
        remark: '',
        isDelete: false,
        createdBy: null,
        createdDate: null,
        modifiedBy: null,
        modifiedDate: null,
        shopID: null,
        shop: null
    },
    dashboard: {
        dailySale: 0,
        monthlySale: 0,
        dailyExpense: 0,
        monthlyExpense: 0,
        salesValue: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [{
                label: 'Sales Value',
                data: [0, 0, 0, 0, 0, 0]
            }]
        },
        totalOrder: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            datasets: [{
                label: 'Total Order',
                data: [0, 0, 0, 0, 0, 0]
            }]
        }
    }
};