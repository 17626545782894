import { getData } from "./fetch";
import { base64ToArrayBuffer, saveByteArray } from './pdf';
import { months, getDateForDisplay } from "./date";

// Enum
export const excelType = {
    stock: 1,
    order: 2,
    finance: 3,
    customer: 4,
};

export const orderType = {
    PreOrder: 1,
    Ordered: 2,
    Buying: 3,
    OutOfStock: 4,
    Shipping: 5,
    Delivering: 6,
    Delivered: 7,
    Sale: 8,
    Return: 9
};

const exportExcel = async (type, obj = null) => {
    var url = '';
    var name = '';
    console.log(type, obj)
    switch (type) {
        case 1:
            url = '/api/Reports/ExportStock';
            var date = obj ? obj : new Date();
            name = 'Stock List(' + getDateForDisplay(date) + ')';
            break;
        case 2:
            var startDate = obj ? obj.startDate : '';
            var endDate = obj ? obj.endDate : '';
            var status = obj ? obj.status : '';
            url = `/api/Reports/ExportOrder?startDate=${startDate}&endDate=${endDate}&status=${status}`;
            var date = `${(startDate || "")}-${(endDate || "")}`;
            name += orderType.PreOrder == status ? 'PreOrder ' : orderType.Sale == status ? 'Sale ' : orderType.Return == status ? 'Return ' : 'Order ';
            name += 'List (' + date + ')';
            break;
        case 3:
            var startdate = obj ? obj.startdate : null;
            var enddate = obj ? obj.enddate : null;
            url = '/api/Reports/ExportFinance?startdate=' + startdate + '&enddate=' + enddate;
            // var date = obj ? months[month - 1] + ', ' + year : 'All';
            name = 'Finance Report (' + getDateForDisplay(startdate) + ' - ' + getDateForDisplay(enddate) + ')';
            break;
        case 4:
            url = '/api/Reports/ExportCustomer';
            var date = obj ? obj : new Date();
            name = 'Customer List(' + getDateForDisplay(date) + ')';
            break;
        default:
            break;
    }
    var response = await getData(url);
    if (response.ok) {
        var data = response.data;
        var sampleArr = base64ToArrayBuffer(data);
        saveByteArray(name + '.xlsx', sampleArr);
    }
};

export default exportExcel;