import React, { Component } from 'react';
import {
  Button,
  Form,
  Row,
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData, getData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import Thumbnail, { SortableThumbnailContainer } from '../../components/Images.js';

const maxPhoto = 3;

export default class ItemForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: this.props.model ? this.props.model : defaultModel.item,
      messageModal: false,
      message: '',
      itemTypes: [],
      suppliers: [],
      supplierPercentage: this.props.model ? (this.props.model.supplier ? this.props.model.supplier.supplierPercentage : 0) : 0
    }
    this.updateModel = this.updateModel.bind(this);
    this.addItemPicture = this.addItemPicture.bind(this);
    this.save = this.save.bind(this);
    this.onSortItems = this.onSortItems.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount = async () => {
    var response = await getData('/api/ItemTypes/GetItemTypeSelectList');
    if (response.ok) {
      this.setState({ itemTypes: response.data });
    }

    var suppliers = await getData('/api/Suppliers/GetSupplierSelectList');
    if (suppliers.ok) {
      this.setState({ suppliers: suppliers.data });
    }
  }

  handleBlur = async (e) => {
    let { name, value } = e.target;
    var key = name;
    if (!value) {
      await this.setState(state => ({
        model: Object.assign({}, state.model, { [key]: 0 })
      }));
      this.calculateSalePrice();
    }
  }

  updateModel = (e) => {
    let { name, value } = e.target;
    var key = name;
    this.setState(state => ({
      model: Object.assign({}, state.model, { [key]: value })
    }));
  }

  calculateSalePrice = () => {
    var weightCost = parseInt(this.state.model.weightCost);
    var purchasePrice = parseInt(this.state.model.purchasePrice);
    //purchasePrice = purchasePrice + weightCost;
    var supplierPercentage = this.state.supplierPercentage;
    var exchangeRate = parseInt(this.state.model.exchangeRate);
    var tax = parseInt(this.state.model.tax);
    //var perItemCostWithoutTax = (purchasePrice + ((purchasePrice * supplierPercentage) / 100)) * exchangeRate;
    //this.state.model.perItemCost = perItemCostWithoutTax + ((perItemCostWithoutTax * tax) / 100);
    var perItemCost = purchasePrice + ((purchasePrice * tax) / 100) + ((purchasePrice * supplierPercentage) / 100) + weightCost;
    this.state.model.perItemCost = perItemCost * exchangeRate;
    this.setState({
      model: JSON.parse(JSON.stringify(this.state.model))
    });
  }

  updateCheckbox = (key, value) => {
    this.setState(state => ({
      model: Object.assign({}, state.model, { [key]: value })
    }));
  }

  addItemPicture = (input) => {
    var thisprops = this;
    if (input.target.files && input.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var newPic = JSON.parse(JSON.stringify(defaultModel.itemPictures));
        newPic.name = e.target.result;
        thisprops.state.model.itemPictures.push(newPic);
        thisprops.setState({ model: thisprops.state.model });
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  }

  save = async (e) => {
    e.preventDefault();
    var response = { ok: false, data: 'Something went wrong' };
    // if (this.state.model.itemPictures.length > 0) {
    if (this.props.action === 'create') {
      response = await postData('/api/Items', this.state.model);
    } else if (this.props.action === 'edit') {
      response = await putData('/api/Items/' + this.state.model.id, this.state.model);
    }
    if (response.ok) {
      this.props.toggle();
      this.props.refreshTable();
    } else {
      this.setState({ messageModal: true, message: response.data });
    }
    // } else {
    //   this.setState({
    //     messageModal: true,
    //     message: "Please add item's photos."
    //   });
    // }
  }

  toggle = () => {
    this.setState(prevState => ({
      messageModal: !prevState.messageModal
    }));
  }

  onSortItems(itemPictures) {
    document.getElementById('imageUploadForm').value = '';
    this.setState(state => ({
      model: Object.assign({}, state.model, { ['itemPictures']: itemPictures })
    }));
  }

  render() {
    return (
      <div>
        <MessageModal
          toggle={this.toggle}
          modal={this.state.messageModal}
          title={'Error'}
          message={this.state.message}
        />
        <Form onSubmit={this.save}>
          <h6 className="heading-small text-muted mb-4">
            Item Photos (max: {maxPhoto})
        </h6>
          <div className="pl-lg-4">
            <Row>
              <div className="col-12">
                <SortableThumbnailContainer images={this.state.model.itemPictures} onSortItems={this.onSortItems} />
                <input hidden type="file" id="imageUploadForm" accept="image/gif, image/jpeg, image/png" onChange={this.addItemPicture} />
                {
                  this.state.model.itemPictures.length < maxPhoto ? (
                    <Thumbnail customPath={true} image={'/img/add.png'} onClick={() => document.getElementById('imageUploadForm').click()} />
                  ) : (<></>)
                }
              </div>
            </Row>
          </div>
          <hr className="my-4" />
          {/* Address */}
          <h6 className="heading-small text-muted mb-4">
            Item information
          </h6>
          <div className="pl-lg-4">
            <Row>
              <ColInput
                col={6}
                label={'Name'}
                defaultValue={this.state.model.itemName}
                id={'itemName'}
                type={'text'}
                onChange={this.updateModel}
                required={true}
              />
              <ColInput
                col={6}
                label={'Barcode'}
                defaultValue={this.state.model.barcode}
                id={'barcode'}
                type={'text'}
                onKeyPress={e => { if (e.charCode === 13) e.preventDefault() }}
                onFocus={(e) => e.target.select()}
                onChange={this.updateModel}
              />
              <ColInput
                col={6}
                label={'Brand'}
                defaultValue={this.state.model.itemBrand}
                id={'itemBrand'}
                type={'text'}
                onChange={this.updateModel}
              />
              <ColInput
                col={6}
                label={'Type'}
                defaultValue={this.state.model.itemTypeID}
                id={'itemTypeID'}
                type={'select'}
                onChange={this.updateModel}
                options={this.state.itemTypes}
                required={true}
              />
              <ColInput
                col={6}
                label={'Preorder'}
                defaultValue={this.state.model.isPreOrderItem}
                id={'isPreOrderItem'}
                type={'switch'}
                onChange={(key, value) => this.updateCheckbox(key, value)}
                hidden={true}
              />
              <ColInput
                col={6}
                label={'Url'}
                defaultValue={this.state.model.url}
                id={'url'}
                type={'text'}
                onChange={this.updateModel}
              />
              <ColInput
                col={6}
                label={'supplier (%)'}
                defaultValue={this.state.model.supplierID}
                id={'supplierID'}
                type={'select'}
                onChange={async (e) => {
                  await this.updateModel(e);
                  var supplier = this.state.suppliers.find(x => x.value == this.state.model.supplierID);
                  this.setState({
                    supplierPercentage: supplier ? supplier.number : 0
                  })
                  this.calculateSalePrice();
                }}
                options={this.state.suppliers}
                // required={true}
              />
              <ColInput
                col={6}
                label={'Quantity'}
                defaultValue={this.state.model.quantityInStock}
                id={'quantityInStock'}
                type={'number'}
                onChange={this.updateModel}
                required={true}
                min={this.state.model.isPreOrderItem ? '' : 0}
              />
              <ColInput
                col={6}
                label={'Purchase Price'}
                value={this.state.model.purchasePrice}
                id={'purchasePrice'}
                type={'number'}
                onChange={async (e) => {
                  await this.updateModel(e);
                  this.calculateSalePrice();
                }}
                onBlur={this.handleBlur}
                // required={true}
                min={0}
              />
              <ColInput
                col={6}
                label={'Weight Cost'}
                value={this.state.model.weightCost}
                id={'weightCost'}
                type={'number'}
                onChange={async (e) => {
                  await this.updateModel(e);
                  this.calculateSalePrice();
                }}
                onBlur={this.handleBlur}
                // required={true}
                min={0}
              />
              <ColInput
                col={6}
                label={'Exchange Rate'}
                value={this.state.model.exchangeRate}
                id={'exchangeRate'}
                type={'number'}
                onChange={async (e) => {
                  await this.updateModel(e);
                  this.calculateSalePrice();
                }}
                onBlur={this.handleBlur}
                // required={true}
                min={0}
              />
              <ColInput
                col={6}
                label={'Tax (%)'}
                value={this.state.model.tax}
                id={'tax'}
                type={'number'}
                onChange={async (e) => {
                  await this.updateModel(e);
                  this.calculateSalePrice();
                }}
                onBlur={this.handleBlur}
                // required={true}
                min={0}
                max={100}
              />
              <ColInput
                col={6}
                label={'Sale Price'}
                value={this.state.model.perItemCost}
                id={'perItemCost'}
                type={'number'}
                onChange={this.updateModel}
                onBlur={this.handleBlur}
                required={true}
                min={0}
              />
            </Row>
          </div>
          <hr className="my-4" />
          {/* Description */}
          <h6 className="heading-small text-muted mb-4">About Item</h6>
          <div className="pl-lg-4">
            <Row>
              <ColInput
                col={12}
                label={'Description'}
                defaultValue={this.state.model.description}
                id={'description'}
                type={'textarea'}
                rows={4}
                onChange={this.updateModel}
              />
              <ColInput
                col={12}
                label={'Remark'}
                defaultValue={this.state.model.remark}
                id={'remark'}
                type={'textarea'}
                rows={4}
                onChange={this.updateModel}
              />
            </Row>
          </div>
          <div className="pl-lg-4 text-right">
            <Button color="primary" type="submit">Save</Button>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </div>
        </Form>
      </div>
    )
  }
}
