import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import {
  Redirect,
} from "react-router-dom";
import { postData } from "../../services/fetch";
import { setToken, removeToken, getToken, setRole, removeRole } from "../../services/storage";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        password: '',
      },
      error: '',
      redirectToIndex: false,
    }

    this.updateData = this.updateData.bind(this);
    this.login = this.login.bind(this);
  }

  async componentDidMount() {
    var token = await getToken();
    if (token) {
      this.setState({ redirectToIndex: true })
    }
  }


  updateData = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((state) => ({
      user: Object.assign({}, state.user, { [name]: value })
    }));
  }

  login = async (e) => {
    e.preventDefault();
    var response = await postData('/api/account/login', this.state.user);
    if (response.ok) {
      var data = response.data;
      if (data.token) { // token is return
        setToken(data.token);
        setRole(data.role);
        this.setState({ error: '', redirectToIndex: true });
      } else { // Invalid Login Attempt
        this.setState({ error: data });
        removeToken();
        removeRole();
      }
    }
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: "/admin/index" } };
    if (this.state.redirectToIndex) return <Redirect to={from} />
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <div className="text-center text-danger mb-4">
                <small>{this.state.error}</small>
              </div>
              <Form role="form" onSubmit={this.login}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" name="username" onChange={this.updateData} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" name="password" onChange={this.updateData} />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6"></Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/admin/forgotpassword"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            {/* <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col> */}
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
