import React, { Component } from 'react';
import {
  Button,
  Form,
  Row,
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import OrderTable from '../../components/Tables/OrderTable.js';

export default class OrderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: this.props.model ? this.props.model : defaultModel.order,
      messageModal: false,
      message: '',
    }
    this.updateModel = this.updateModel.bind(this);
  }

  updateModel = (model) => {
    this.setState({ model: model });
  }

  toggle = () => {
    this.setState(prevState => ({
      messageModal: !prevState.messageModal
    }));
  }

  render() {
    return (
      <div>
        <OrderTable model={this.state.model} updateModel={(model) => this.updateModel(model)} toggle={this.props.toggle} />
      </div>
    )
  }
}
