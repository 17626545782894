import React, { Component } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button,
  Form,
  Row,
} from "reactstrap";

export default class SimpleModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className} size={'lg'}>
        <ModalHeader toggle={this.props.toggle} style={{ background: 'white' }}>{this.props.title}</ModalHeader>
        <ModalBody className={'bg-secondary shadow card'} style={{ ...this.props.style, borderRadius: '0 0 5px 5px' }}>
          {this.props.children}
        </ModalBody>
      </Modal>
    )
  }
}

export class MessageModal extends Component {
  render() {
    return (
      <SimpleModal toggle={this.props.toggle} modal={this.props.modal} title={this.props.title}>
        <h3>{this.props.message}</h3>
      </SimpleModal>
    )
  }
}

export class ConfirmModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className} size={'lg'}>
        <ModalHeader toggle={this.props.toggle} style={{ background: 'white' }}>{this.props.title}</ModalHeader>
        <ModalBody className={'bg-secondary shadow card'} style={{ borderRadius: 0 }}>
          <h3>{this.props.confirmText}</h3>
        </ModalBody>
        <ModalFooter>
          <div className="pl-lg-4 text-right">
            <Button color="primary" onClick={this.props.onConfirm}>{this.props.buttonText}</Button>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}