import React, { Component } from 'react'
import { ThumbnailContainer } from '../Images';

export default class DetailPanel extends Component {
    render() {
        return (
            <>
                {
                    this.props.images ? (
                        <div className="row px-5 py-2 mx-5">
                            <div className="col-12">
                                <ThumbnailContainer images={this.props.images} onSortItems={this.onSortItems} />
                            </div>
                        </div>

                    ) : (<></>)
                }
                <div className="row px-5 py-2 mx-5">
                    {
                        this.props.data.map((item, i) =>
                            <div className={item.full ? 'col-12' : 'col-6'} key={i}>
                                <div className="row py-2">
                                    <div className={item.full ? 'col-2' : 'col-4'}>
                                        <span className="MuiTableCell-head MuiTableCell-alignLeft MTableHeader-header-140">
                                            {item.label}
                                        </span>
                                    </div>
                                    <div className="col-8">{item.href ? (<a href={item.href} target='_blank'>{item.value}</a>) : item.value}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}