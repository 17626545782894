import React, { useState } from "react";

// reactstrap components
import {
  UncontrolledTooltip,
  Container,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { getData } from 'services/fetch';
import MaterialTable, { MTableToolbar } from "material-table";
import DetailPanel from "../../components/Tables/DetailPanel";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal.jsx";
import OrderForm from "./OrderForm";
import {
  Redirect,
} from "react-router-dom";
import { defaultModel } from "../../components/DefaultModel";
import { deleteData, postData } from "../../services/fetch";
import { getDateForInput, getDateForDisplay, months, getYears, getDays } from "../../services/date";
import { base64ToArrayBuffer, saveByteArray } from "../../services/pdf";
import { arraySum, sort } from "../../services/common";
import OrderTable, { OrderTableDetail } from "../../components/Tables/OrderTable";
import { setPreferences, getPreferences, isInRole } from "../../services/storage";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import exportExcel, { excelType } from "../../services/excel";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    border: 'none',
    width: '100%',
    borderBottom: '1px solid #e0e0e0',
  }
}));

export default function Orders() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <Header />
      {/* Page content */}
      <Container className="mt--9" fluid>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="All" />
            <Tab label="Preorder" />
            <Tab label="Order" />
            <Tab label="Sale" />
            <Tab label="Return" />
          </Tabs>
        </AppBar>
        {value === 0 && <OrderTabs title='All Orders' status={0} />}
        {value === 1 && <OrderTabs title='Preorders' status={1} excelType={excelType.order} />}
        {value === 2 && <OrderTabs title='Orders' status={2} excelType={excelType.order} />}
        {value === 3 && <OrderTabs title='Sales' status={8} excelType={excelType.order} />}
        {value === 4 && <OrderTabs title='Return' status={9} excelType={excelType.order} />}
      </Container>
    </div>
  );
}

export class OrderTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      modal: false,
      model: defaultModel.ordersFromCustomer,
      action: '',
      redirectToLogin: false,
      selectedIds: [],
      idToDelete: '',
      receiptType: getPreferences('receipt') ? getPreferences('receipt') : 'slip',
      startDate: '',
      endDate: '',
    };

    this.tableRef = React.createRef();
    this.delete = this.delete.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: false,
      deleteModal: false,
      model: defaultModel.ordersFromCustomer,
      idToDelete: '',
    });
  }

  refreshTable = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  delete = async () => {
    var response = await deleteData('/api/OrdersFromCustomers/' + this.state.idToDelete);
    if (response.ok) {
      this.toggle();
      this.refreshTable();
    }
  }

  render() {
    const thisprops = this;
    const years = getYears();
    if (this.state.redirectToLogin) return <Redirect to={{
      pathname: "/auth/login",
      state: { from: this.props.location }
    }} />
    return (
      <>
        <MaterialTable
          title={this.props.title}
          tableRef={this.tableRef}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div className="row" style={{ padding: '0 25px' }}>
                  <div className="row col-md-6 col-sm-12">
                    {
                      this.props.excelType && isInRole('admin') ? (
                        <OrderExportSection status={this.props.status} />
                      ) : (<></>)
                    }
                  </div>
                  <div className="row col-md-6 col-sm-12">
                    <label className="col-md-10 col-sm-8 pt-2" style={{ textAlign: 'right' }}>Get Receipt With</label>
                    <select className='form-control col-md-2 col-sm-4' id='receiptType' value={this.state.receiptType} onChange={(e) => {
                      setPreferences('receipt', e.target.value);
                      this.setState({ receiptType: e.target.value });
                    }}>
                      <option value='slip'>Slip</option>
                      <option value='pdf'>PDF</option>
                    </select>
                  </div>
                </div>
              </div>
            ),
          }}
          columns={[
            { title: 'Voucher No.', field: 'voucherNo' },
            { title: 'Date', field: 'date', render: rowData => getDateForDisplay(rowData.date) },
            { title: 'Customer', field: 'customer.name', render: rowData => rowData.customerID != '00000000-0000-0000-0000-000000000000' ? rowData.customer.name : rowData.guestCustomerName },
            // {
            //   title: 'Item', field: '', render: rowData =>
            //     <div className="avatar-group">
            //       {
            //         sort(rowData.orderItems, 'sortOrder').map((orderItem, i) =>
            //           <a
            //             className="avatar avatar-sm"
            //             href="#pablo"
            //             id={"orderitem" + orderItem.id}
            //             onClick={e => e.preventDefault()}
            //             key={i}
            //           >
            //             <img
            //               alt="..."
            //               className="rounded-circle"
            //               src={"/img/items/small/" + orderItem.item.itemPictures[0].name}
            //             />
            //             <UncontrolledTooltip
            //               delay={0}
            //               target={"orderitem" + orderItem.id}
            //             >
            //               {orderItem.item.itemName}
            //             </UncontrolledTooltip>
            //           </a>
            //         )
            //       }
            //     </div>
            // },
            { title: 'Total (delivery not included)', field: '', render: rowData => arraySum(rowData.orderItems, 'totalCost') - rowData.discount },
          ]}
          data={async function (query) {
            let url = '/api/OrdersFromCustomers?'
            url += 'per_page=' + query.pageSize
            url += '&page=' + (query.page + 1)
            url += '&search=' + query.search
            url += '&status=' + thisprops.props.status
            var result = await getData(url);
            if (result.ok) {
              var data = result.data;
              data.data = data.data.map(x => Object.assign({}, x, { date: getDateForInput(x.date) }));
              return {
                data: data.data,
                page: data.page - 1,
                totalCount: data.total
              }
            } else if (result.status === 401) {
              thisprops.setState({ redirectToLogin: true });
            } else {
              return {
                data: [],
                page: 1,
                totalCount: 0
              }
            }
          }}
          detailPanel={rowData => {
            return (
              <DetailPanel
                data={[
                  { label: 'Address', value: rowData.customerAddress },
                  { label: 'Township', value: rowData.township },
                  { label: 'Phone', value: rowData.customerPhone },
                  { label: 'Delivery', value: rowData.deliveryman.name == 'Not Assigned' ? 'Pick Up' : rowData.deliveryman.name },
                  { label: 'Status', value: rowData.orderState.orderState1 },
                  { label: 'Remark', value: rowData.remark },
                  {
                    label: '', value: (
                      <OrderTableDetail
                        items={rowData.orderItems}
                        advancePayment={rowData.advancePayment}
                        discount={rowData.discount}
                        tax={rowData.tax}
                        deliveryCharge={rowData.deliveryCharge}
                      />
                    ), full: true
                  },
                ]}
              />
            )
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          options={{
            search: true,
            selection: false,
            debounceInterval: 500
          }}
          onSelectionChange={(rows) => {
            this.setState(state => ({
              selectedIds: rows.map(row => row.id)
            }));
          }}
          // options={{
          //   actionsColumnIndex: -1
          // }}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: this.refreshTable
            },
            {
              icon: 'add_circle',
              tooltip: 'Add Order',
              isFreeAction: true,
              onClick: () => { this.setState({ action: 'create', modal: true }); },
            },
            {
              tooltip: 'Receipt',
              icon: 'save_alt',
              onClick: async (evt, data) => {
                if (this.state.receiptType == "pdf") {
                  var response = await getData("/api/ordersFromCustomers/ReceiptPdfUnicode?id=" + data.id);
                  if (response.ok) {
                    var sampleArr = base64ToArrayBuffer(response.data);
                    saveByteArray('Receipt (Voucher No_ ' + data.voucherNo + ').pdf', sampleArr);
                  }
                } else if (this.state.receiptType == "slip") {
                  var response = await getData("/api/ordersFromCustomers/ReceiptSlip?id=" + data.id);
                  if (response.ok) {
                    fetch("http://localhost:8888/", {
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(response.data),
                    })
                  }
                }
              }
            },
            {
              tooltip: 'Edit',
              icon: 'edit',
              onClick: async (evt, data) => {
                this.setState({ modal: true, model: JSON.parse(JSON.stringify(data)), action: 'edit' });
              }
            },
            {
              tooltip: 'Remove',
              icon: 'delete',
              onClick: (evt, data) => this.setState({ deleteModal: true, idToDelete: data.id })
            }
          ]}
        />

        <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Create a new order'} style={{ padding: 0 }}>
          <OrderTable
            model={this.state.model}
            action={this.state.action}
            toggle={this.toggle}
            refreshTable={this.refreshTable}
          />
        </SimpleModal>
        <ConfirmModal
          toggle={this.toggle}
          modal={this.state.deleteModal}
          title={'Delete Confirmation'}
          confirmText={'Are you sure you want to delete this order?'}
          buttonText={'Delete'}
          onConfirm={this.delete}
        />
      </>
    );
  }
}

export const OrderExportSection = props => {

  const classes = useStyles();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <div style={{ marginBottom: 15, marginLeft: 15 }}>
      <Grid container spacing={3} className='d-flex flex-row align-items-end'>
        <Grid item xs={4}>
          <small>Start Date</small>
          <input type='date' value={startDate} onChange={e => setStartDate(e.target.value)} className={classes.input} />
        </Grid>
        <Grid item xs={4}>
          <small>End Date</small>
          <input type='date' value={endDate} onChange={e => setEndDate(e.target.value)} className={classes.input} />
        </Grid>
        <Grid item xs={4}>
          <a className="btn" style={{ marginLeft: 5, border: '1px solid #cad1d7', padding: "5px 10px;" }}
            onClick={() => {
              exportExcel(excelType.order, { startDate, endDate, status: props.status })
            }}>
            <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">save_alt</span>
          </a>
        </Grid>
      </Grid>
    </div>
  )
}